import styled, { css } from "styled-components"
import { readableColor } from "polished"

export const Icon = styled.svg`
  width: 1.2rem;
  height: 1.2rem;
  fill: ${(props) =>
    readableColor(
      props.theme.colors.secondary,
      props.theme.colors.buttons.darkColor,
      props.theme.colors.buttons.lightColor,
      false
    )};
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  ${(props) =>
    props.grey &&
    css`
      fill: #414141;
    `}

  ${(props) =>
    props.primary &&
    css`
      fill: ${(props) => props.theme.colors.primary};
    `}

  ${(props) =>
    props.direction === "up" &&
    css`
      transform: rotate(270deg);
    `}

  ${(props) =>
    props.direction === "right" &&
    css`
      transform: rotate(0deg);
    `}

  ${(props) =>
    props.direction === "down" &&
    css`
      transform: rotate(90deg);
    `}

  ${(props) =>
    props.direction === "left" &&
    css`
      transform: rotate(180deg);
    `}

  ${(props) =>
    props.large &&
    css`
      width: 1.7rem;
      height: 1.7rem;
    `}

  ${(props) =>
    props.menu &&
    css`
      fill: ${(props) => props.theme.colors.body};
      width: 0.8em;
      height: 0.8em;
    `}
`
