import React from "react"
import { Icon, Path, Ellipse } from "./styles"

const CloseIcon = (props) => (
  <Icon
    viewBox="0 0 35 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Postcard---Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Background---Dialog" transform="translate(-1240.000000, -408.000000)" fillRule="nonzero">
        <g id="Group-3" transform="translate(398.000000, 384.000000)">
          <g id="Close-Icon" transform="translate(842.000000, 24.000000)">
            <Path
              d="M10.5833333,16.9166667 L24.4028742,17.1113081 C24.9441371,17.1189315 25.3810685,17.5558629 25.3886919,18.0971258 L25.3888889,18.1111111 C25.3963464,18.6405971 24.9731588,19.0758758 24.4436728,19.0833333 C24.4346712,19.0834601 24.4256683,19.0834601 24.4166667,19.0833333 L10.5971258,18.8886919 C10.0558629,18.8810685 9.61893151,18.4441371 9.61130809,17.9028742 L9.61111111,17.8888889 C9.60365356,17.3594029 10.0268412,16.9241242 10.5563272,16.9166667 C10.5653288,16.9165399 10.5743317,16.9165399 10.5833333,16.9166667 Z"
              id="Rectangle-Copy"
              transform="translate(17.500000, 18.000000) rotate(-315.000000) translate(-17.500000, -18.000000) "
              {...props}
            ></Path>
            <Path
              d="M10.5833333,16.9166667 L24.4028742,17.1113081 C24.9441371,17.1189315 25.3810685,17.5558629 25.3886919,18.0971258 L25.3888889,18.1111111 C25.3963464,18.6405971 24.9731588,19.0758758 24.4436728,19.0833333 C24.4346712,19.0834601 24.4256683,19.0834601 24.4166667,19.0833333 L10.5971258,18.8886919 C10.0558629,18.8810685 9.61893151,18.4441371 9.61130809,17.9028742 L9.61111111,17.8888889 C9.60365356,17.3594029 10.0268412,16.9241242 10.5563272,16.9166667 C10.5653288,16.9165399 10.5743317,16.9165399 10.5833333,16.9166667 Z"
              id="Rectangle-Copy"
              transform="translate(17.500000, 18.000000) scale(-1, 1) rotate(-315.000000) translate(-17.500000, -18.000000) "
              {...props}
            ></Path>
            <Ellipse id="Oval" strokeWidth="2" cx="17.5" cy="18" rx="16.5" ry="17" {...props}></Ellipse>
          </g>
        </g>
      </g>
    </g>
  </Icon>
)

export default CloseIcon
