import { createBrowserHistory } from "history"
import { matchPath } from "react-router-dom"

import * as Sentry from "@sentry/react"

const history = createBrowserHistory()

// Array of Route Config Objects
// Make sure the order of the routes is correct. The longest url under the same parent should be placed first and in decreasing order.
const routes = []

let enableSentry = process.env.REACT_APP_ENV !== "development"
if (process.env.REACT_APP_ENV === "development" && process.env.REACT_APP_ENABLE_ENTRY_IN_DEV === "true")
  enableSentry = true

if (enableSentry) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
        routes,
        matchPath
      })
    ],
    environment: process.env.REACT_APP_ENV,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}
