import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { useContext } from "react"
import MenuContext from "@context/MenuContext"

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  width: 100%;
  height: 100dvh;
  z-index: 50;
  overflow-y: auto;
  font-size: 1em;
  left: 100%;
  left: 100vw;
  left: 100dvw;
  transition: left 0.3s ease-in-out;
  width: 100%;
  width: 100vw;
  width: 100dvw;
  position: fixed;

  ${(props) =>
    props.showMenu &&
    css`
      left: 0;
    `}

  ${breakpoint("desktop")`
    display: block;
    top: 0;
    left: 0;
    width: 25%;
    width: 25vw;
    width: 25dvw;
    max-width: 360px;
    min-width: 270px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
    position: sticky;
  `}
`

export const Inner = ({ children }) => {
  const { showMobileApplicationList } = useContext(MenuContext)
  return <InnerWrapper showMobileApplicationList={showMobileApplicationList}>{children}</InnerWrapper>
}

const InnerWrapper = styled.div`
  position: relative;
  left: 0;
  transition: left 0.3s ease-in-out;

  ${(props) =>
    props.showMobileApplicationList &&
    css`
      left: -100%;
      left: -100vw;
      left: -100dvw;
    `}

  ${breakpoint("desktop")`
    height: 100vh;
    display: flex;
    flex-direction: column;
  `}
`

export const Nav = styled.div`
  padding: 1.2rem;

  ${breakpoint("desktop")`
    padding: 2rem 1.375rem 2rem 0.5rem;
    flex: 1 1 auto;
    overflow-y: auto;
    scrollbar-gutter: stable both-edges;
    height: 0px;
  `}

  &::-webkit-scrollbar {
    width: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.primary};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.primaryContrastedDarker};
  }
`
