import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"

export const Container = styled.div`
  box-sizing: border-box;

  ${(props) =>
    props.nomargin &&
    css`
      margin: 0;
    `}

  ${(props) =>
    !props.nomargin &&
    css`
      margin-bottom: 1rem;

      ${breakpoint("desktop")`
      margin-bottom: 2rem;
    `}
    `}

  ${(props) =>
    props.mobileOnly &&
    css`
      width: 100%;

      ${breakpoint("desktop")`
      display: none;
    `}
    `}

  ${(props) =>
    props.desktopOnly &&
    css`
      display: none;

      ${breakpoint("desktop")`
        width: 50%;
        display: block;
      `}
    `}
`

export const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => props.theme.colors.applicationProgress.label};

  ${(props) =>
    props.body &&
    css`
      color: ${props.theme.colors.body};
    `}

  span {
    color: ${(props) => props.theme.colors.applicationProgress.stage};
    font-weight: 500;
  }
`

export const Blocks = styled.div`
  margin-top: 0.3em;
  gap: 3px;
  display: flex;
  justify-content: start;
`

export const Progress = styled.progress`
  &[value] {
    appearance: none;
    width: 100%;
    height: 0.5rem;

    &::-webkit-progress-bar {
      background-color: ${(props) => props.theme.colors.applicationProgress.bar};
      border-radius: ${(props) => {
        if (props.stage === 1) return "6px 1px 1px 6px"
        else if (props.stage === props.lastStage) return "1px 6px 6px 1px"
        else return "1px"
      }};
    }

    &::-webkit-progress-value {
      background-color: ${(props) => props.theme.colors.applicationProgress.value};
      border-radius: ${(props) => {
        if (props.stage === 1) return "6px 1px 1px 6px"
        else if (props.stage === props.lastStage) return "1px 6px 6px 1px"
        else return "1px"
      }};

      ${(props) =>
        props.body &&
        css`
          background-color: ${props.theme.colors.body};
        `}
    }
  }
`
