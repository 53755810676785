import React from "react"
import PropTypes from "prop-types"
import { Button } from "./styles"
import { Counter } from "../header/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBell } from "@fortawesome/pro-light-svg-icons"

const OpenNotificationsButton = ({ notificationCount }) => (
  <Button to="/notifications">
    <FontAwesomeIcon icon={faBell} size="1x" />
    {notificationCount > 0 && <Counter>{notificationCount}</Counter>}
  </Button>
)

OpenNotificationsButton.propTypes = {
  notificationCount: PropTypes.number
}

export default OpenNotificationsButton
