import styled from "styled-components"

export const Icon = styled.svg`
  height: 2.5rem;
  width: 2.5rem;
  fill: ${(props) => props.theme.colors.body};
  cursor: pointer;

  ${(props) =>
    props.primary &&
    `
    fill: ${props.theme.colors.primary};
  `}

  ${(props) =>
    props.content &&
    `
    fill: ${props.theme.colors.content.text};
  `}
`

export const Path = styled.path`
  fill: ${(props) => props.theme.colors.body};

  ${(props) =>
    props.primary &&
    `
    fill: ${props.theme.colors.primary};
  `}

  ${(props) =>
    props.content &&
    `
    fill: ${props.theme.colors.content.text};
  `}
`

export const Ellipse = styled.ellipse`
  stroke: ${(props) => props.theme.colors.body};

  ${(props) =>
    props.primary &&
    `
    stroke: ${props.theme.colors.primary};
  `}

  ${(props) =>
    props.content &&
    `
    stroke: ${props.theme.colors.content.text};
  `}
`
