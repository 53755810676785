import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Text = styled.div`
  font-family: ${(props) => props.theme.fonts.headings.family}, sans-serif;
  font-weight: ${(props) => props.theme.fonts.headings.weight};
  font-size: 1.75rem;
  text-align: center;
  margin-bottom: 2rem;

  ${breakpoint("desktop")`
    font-size: 2.5rem;
  `}
`
