import React from "react"
import PropTypes from "prop-types"
import { StyledHr } from "./styles"

const Separator = (props) => <StyledHr {...props} />

Separator.defaultProps = {
  mobileOnly: true
}

Separator.propTypes = {
  mobileOnly: PropTypes.bool,
  light: PropTypes.bool
}

export default Separator
