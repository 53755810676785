import React from "react"
import { Container, Spinner, Circle } from "./styles"

const LoadingBox = () => (
  <Container>
    <Spinner>
      <Circle></Circle>
      <Circle></Circle>
      <Circle></Circle>
      <Circle></Circle>
      <Circle></Circle>
      <Circle></Circle>
      <Circle></Circle>
      <Circle></Circle>
      <Circle></Circle>
      <Circle></Circle>
      <Circle></Circle>
      <Circle></Circle>
    </Spinner>
  </Container>
)

export default LoadingBox
