const americancampco = {
  id: 16,
  name: "americancampco",
  friendlyName: "American Camp Co",
  tagline: "Your adventure starts here.",
  apply: "Apply now for summer 2024",
  links: {
    appBase: {
      development: "http://americancampco.test:3000",
      staging: "https://mf-staging.americancampco.com",
      production: "https://americancampco.smallerearth.com"
    },
    baseWithoutProtocol: "www.americancampco.com"
  },
  employerType: "Camp"
}

export default americancampco
