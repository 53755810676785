const smallerearth = {
  id: 5,
  name: "smallerearth",
  friendlyName: "Smaller Earth",
  tagline: "Your adventure starts here.",
  apply: "Apply now for summer 2024",
  links: {
    appBase: {
      development: "http://smallerearth.test:3000",
      staging: "https://mf-staging.smallerearth.com",
      production: "https://login.smallerearth.com"
    },
    baseWithoutProtocol: "my.smallerearth.com"
  },
  employerType: "Camp"
}

export default smallerearth
