import React from "react"
import { Route } from "react-router-dom"
import RedirectToHome from "./redirect-to-home"

const PublicRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route {...rest} render={(props) => (isAuthenticated ? <RedirectToHome {...props} /> : <Component {...props} />)} />
)

PublicRoute.defaultProps = {
  isAuthenticated: false
}

export default PublicRoute
