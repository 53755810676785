import React, { useContext } from "react"
import PropTypes from "prop-types"
import Logo from "../../logo"
import CloseButton from "../../close-button"
import { Container } from "./styles"
import { Link } from "react-router-dom"
import MenuContext from "@context/MenuContext"

const Top = (props) => {
  const closeMenu = (event) => {
    event.preventDefault()
    toggleMenu()
  }

  const { toggleMenu } = useContext(MenuContext)

  return (
    <Container>
      <Link to="/">
        <Logo />
      </Link>
      <CloseButton id="mobileMenuButton" onClick={closeMenu} mobileOnly />
    </Container>
  )
}

Top.propTypes = {
  toggleMenu: PropTypes.func
}

export default Top
