const adventureasia = {
  id: 17,
  name: "adventureasia",
  friendlyName: "Adventure Asia",
  tagline: "The gateway to your teaching adventure.",
  apply: "Apply now",
  links: {
    appBase: {
      development: "http://adventureasia.test:3000",
      staging: "https://mf-staging.adventureasia.com",
      production: "https://login.adventureasia.com"
    },
    baseWithoutProtocol: "www.adventureasia.com"
  },
  programmes: {},
  employerType: "School"
}

export default adventureasia
