import styled, { createGlobalStyle } from "styled-components"
import breakpoint from "styled-components-breakpoint"

export const GlobalStyles = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    height: 100vh;
    width: 100%;
    font-size: 16px;
    font-family: ${(props) => props.theme.fonts.body.family}, sans-serif;
    font-weight: ${(props) => props.theme.fonts.body.weight};
    margin: 0;
    padding: 0;
  }

  @supports (-webkit-touch-callout: none) {
    html {
      height: -webkit-fill-available !important;
    }
  }

  body {
    background-color: ${(props) => props.theme.colors.brand};
    color: ${(props) => props.theme.colors.body};
    margin: 0;
    padding: 0;

    ${breakpoint("desktop")`
      overflow-x: hidden;
    `}
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available !important;
  }
`
