import React from "react"
import PropTypes from "prop-types"
import { Button } from "./styles"
import CloseIcon from "../close-icon"

const CloseButton = ({ onClick, mobileOnly, ...rest }) => (
  <Button href="" onClick={onClick} mobileOnly={mobileOnly} {...rest}>
    <CloseIcon {...rest} />
  </Button>
)

PropTypes.propTypes = {
  onClick: PropTypes.func.isRequired,
  mobileOnly: PropTypes.bool.isRequired,
  small: PropTypes.bool.isRequired
}

CloseButton.defaultProps = {
  mobileOnly: false,
  small: false
}

export default CloseButton
