import smallerearth from "./smallerearth"
import campleaders from "./campleaders"
import resortleaders from "./resortleaders"
import adventurechina from "./adventurechina"
import adventureasia from "./adventureasia"
import campcanada from "./campcanada"
import americancampco from "./americancampco"
import canago from "./canago"

const brands = {
  smallerearth: smallerearth,
  campleaders: campleaders,
  resortleaders: resortleaders,
  adventurechina: adventurechina,
  adventureasia: adventureasia,
  campcanada: campcanada,
  americancampco: americancampco,
  canago: canago
}

export default brands
