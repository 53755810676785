export const fathomSiteIds = {
  GLOBAL: "BFNAWROQ",
  ADVENTURE_ASIA: "TGKVMVLQ",
  ADVENTURE_CHINA: "EPCGGVII",
  AMERICAN_CAMP_CO: "RKBOQMOM",
  CAMP_LEADERS: "MYEXWSCR",
  CANAGO: "PQJAEGHH",
  RESORT_LEADERS: "CENHJHUO"
}

export function fetchFathomSiteId(channel, user = null) {
  if (!channel) return

  channel = channel.toUpperCase().replace(/ /g, "_")
  if (user && user.id) {
    return fathomSiteIds["GLOBAL"]
  } else {
    return fathomSiteIds[channel]
  }
}
