import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.2rem 1rem 1.7rem;
  align-items: center;

  ${breakpoint("desktop")`
    margin-top: 0;
  `}
`
