export const countries = [
  { id: 248, country: "United Kingdom", country_phone_code: "44" },
  { id: 224, country: "United States", country_phone_code: "1" }
]

export function countriesForSelect() {
  let arr = []

  countries.forEach((country) => {
    arr.push([country.id, country.country])
  })

  return arr
}

export function phoneCodesForSelect() {
  let arr = []

  countries.forEach((country) => {
    arr.push([country.id, country.country_phone_code])
  })

  return arr
}

export const genders = [
  { name: "Female", code: "f" },
  { name: "Male", code: "m" },
  { name: "Prefer to Self-Describe", code: "x" }
]

export function gendersForSelect() {
  let arr = []

  genders.forEach((gender) => {
    arr.push([gender.code, gender.name])
  })

  return arr
}

export function pronounsForSelect() {
  return [
    ["she_her", "She/her"],
    ["he_him", "He/him"],
    ["they_them", "They/them"],
    ["prefer_not_to_say", "Prefer not to say"]
  ]
}

export const programmeMap = {
  1: "FT Counselor"
}

export function getProgrammeFromId(id) {
  return programmeMap[1]
}

export const channelMap = {
  3: "campleaders",
  4: "canago",
  5: "smallerearth",
  9: "resortleaders",
  11: "adventurechina",
  17: "adventureasia",
  12: "campcanada",
  16: "americancampco"
}

export function getBrandFromId(id) {
  return channelMap[id]
}
