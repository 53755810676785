export function onVisibilityChange() {
  if (isDocumentHidden()) {
    resetTimeout()
  } else {
    checkTimeout()
    clearTimeout()
  }
}

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange"
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange"
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange"
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden"
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden"
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden"
  }
}

export function isDocumentHidden() {
  return document[getBrowserDocumentHiddenProp()]
}

export function resetTimeout() {
  window.lastActiveAt = Date.now()
}

export function checkTimeout() {
  // If the user has been inactive for more than 3 hours, refresh the page
  const timeout = 1000 * 60 * 60 * 3 // 3 hours
  if (Date.now() - window.lastActiveAt > timeout) {
    window.location.reload()
  }
}

export function clearTimeout() {
  window.lastActiveAt = null
}
