import React from "react"
import PropTypes from "prop-types"
import { Button } from "./styles"
import { Counter } from "../header/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMessage } from "@fortawesome/pro-light-svg-icons"

const OpenMessagesButton = ({ messageCount }) => (
  <Button to="/conversations">
    <FontAwesomeIcon icon={faMessage} size="1x" />
    {messageCount > 0 && <Counter>{messageCount}</Counter>}
  </Button>
)

OpenMessagesButton.propTypes = {
  toggleMessages: PropTypes.func,
  messageCount: PropTypes.number
}

export default OpenMessagesButton
