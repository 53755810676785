import React, { Component } from "react"
import BrandContext from "@context/BrandContext.js"
import { Container, Img } from "./styles"

class Logo extends Component {
  static contextType = BrandContext

  render() {
    return (
      <Container {...this.props}>
        <Img src={`/assets/${this.context.name}/logo.svg`} alt={this.context.friendlyName} />
      </Container>
    )
  }
}

export default Logo
