const resortleaders = {
  id: 9,
  name: "resortleaders",
  friendlyName: "Resort Leaders",
  tagline: "Welcome to the club.",
  apply: "Apply now for summer 2024",
  links: {
    appBase: {
      development: "http://resortleaders.test:3000",
      staging: "https://mf-staging.resortleaders.com",
      production: "https://login.resortleaders.com"
    },
    baseWithoutProtocol: "www.resortleaders.com"
  },
  employerType: "Resort"
}

export default resortleaders
