import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"

export const Container = styled.header`
  position: relative;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${({ withVideo }) =>
    !withVideo &&
    css`
      background-color: ${(props) => props.theme.colors.primary};
    `}

  ${({ isHome }) =>
    isHome &&
    breakpoint("desktop")`
    margin-bottom: 2rem;
    min-height: 15rem;
    border-radius: 1rem;
    overflow: hidden;
  `}
`

export const Video = styled.video`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background: #000;
  filter: brightness(70%);
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.1rem;

  ${breakpoint("desktop")`
    gap: 0.3rem;
    top: 2px;
    position: relative;
  `}

  ${({ isMobileLayout }) =>
    !isMobileLayout &&
    css`
      display: none;

      ${breakpoint("tablet")`
        display: flex;
      `}
    `}

  ${({ isMobileLayout }) =>
    !!isMobileLayout &&
    css`
      display: flex;

      ${breakpoint("desktop")`
      display: none;
    `}
    `}
`

export const Brand = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  ${breakpoint("desktop")`
    margin-top: 0;
  `};
`

export const HeaderBar = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  background-size: cover;
  gap: 0.1rem;
  padding: 0 0 1.3em;
  margin: -7px 0 4px;
  background-color: ${(props) => props.theme.colors.background};

  ${breakpoint("desktop")`
    display: flex;
  `}
`

export const BrandOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0.8em;
  z-index: 20;

  ${breakpoint("desktop")`
    display: none;
  `}
`

export const Counter = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 13px;
  background-color: ${(props) => props.theme.colors.notifications.badge.background};
  border-radius: 50%;
  color: ${(props) => props.theme.colors.notifications.badge.text};
  padding: 0.3em 0.35em 0.35em 0.3em;
  margin-left: 0.5rem;
  text-align: center;
  min-width: 0.9rem;
  min-height: 0.9rem;
  font-size: 12px;
  font-weight: 700;
`
