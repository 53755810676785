import styled, { css } from "styled-components"
import { transparentize } from "polished"
import breakpoint from "styled-components-breakpoint"

export const StyledHeading = styled.h1`
  margin: 0;
  letter-spacing: -1px;

  ${breakpoint("desktop")`
  
  ${({ simple, theme }) =>
    !simple &&
    css`
      letter-spacing: -2px;
      border-radius: 1rem;
      padding: 2rem;
      margin-bottom: 2rem;
      font-size: 3rem;
      background-color: ${theme.colors.primary};
      background-image: linear-gradient(to top, ${theme.colors.primary}, ${transparentize(0.5, theme.colors.primary)}),
        url("${theme.images.background}");
      background-size: cover;
      background-position: center 20%;
    `}
  `}

  ${({ simple, theme }) =>
    !!simple &&
    css`
      font-size: 32px;
      color: ${theme.colors.fields.text};
      margin-bottom: 32px;
    `}
`
