import React from "react"
import Separator from "../separator"
import { Card, Markdown } from "@smaller-earth/postcard-components"

const Incompletion = ({ message, style }) =>
  message ? (
    <>
      <Card variant="incompletion" style={style}>
        <Markdown componentStyles={{ generic: { bold: true } }}>{message}</Markdown>
      </Card>
      <Separator />
    </>
  ) : null

export default Incompletion
