import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { Link } from "react-router-dom"

export const Button = styled(Link)`
  background-color: transparent;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  apperance: none;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.body};
  font-size: 22px;

  ${breakpoint("desktop")`
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
  `}/* &:hover {
    color: ${(props) => props.theme.colors.darkGrey};
    background-color: ${(props) => props.theme.colors.body};
  } */
`
