import { Link } from "react-router-dom"
import styled, { css } from "styled-components"
import { lighten, readableColor } from "polished"
import breakpoint from "styled-components-breakpoint"

export const styles = css`
  appearance: none;
  border-color: transparent;
  border-radius: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: bold;
  height: 3rem;
  margin: 0;
  padding: 0.75rem 1.5rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;

  ${(props) =>
    props.notBold &&
    css`
      font-weight: normal;
    `}

  ${(props) =>
    props.alwaysCollapsed &&
    css`
      width: auto;
    `}

  ${(props) =>
    props.collapsed &&
    css`
      width: 100%;

      ${breakpoint("tablet")`
      width: auto;
    `}
    `}

  ${(props) =>
    props.link &&
    css`
      padding: 0;
      text-decoration: underline;
      background-color: transparent;
      color: ${(props) => props.theme.colors.secondary};
      text-align: left;

      ${(props) =>
        props.inline &&
        css`
          display: inline;
          font-size: 1rem;
          color: ${readableColor(
            props.theme.colors.body,
            props.theme.colors.buttons.lightColor,
            props.theme.colors.buttons.darkColor,
            false
          )};
        `}

      ${(props) =>
        props.secondaryText &&
        css`
          color: ${(props) =>
            readableColor(
              props.theme.colors.secondary,
              props.theme.colors.buttons.darkColor,
              props.theme.colors.buttons.lightColor
            )};
        `}
    `}

  &[disabled], &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  ${(props) =>
    props.primary &&
    css`
      background: ${(props) => props.theme.colors.primary};
      color: ${(props) =>
        readableColor(
          props.theme.colors.secondary,
          props.theme.colors.buttons.darkColor,
          props.theme.colors.buttons.lightColor,
          false
        )};

      &:not([disabled]):hover {
        background-color: ${lighten(0.05, props.theme.colors.primary)};
      }
    `}

  ${(props) =>
    props.secondary &&
    css`
      background: ${(props) => props.theme.colors.secondary};
      color: ${(props) =>
        readableColor(
          props.theme.colors.secondary,
          props.theme.colors.buttons.darkColor,
          props.theme.colors.buttons.lightColor
        )};

      &:not([disabled]):hover {
        background-color: ${lighten(0.05, props.theme.colors.secondary)};
      }

      &:disabled,
      &[disabled] {
        opacity: 0.5;
      }

      ${(props) =>
        props.outlined &&
        css`
          border: 2px solid ${(props) => props.theme.colors.secondaryAlternative || props.theme.colors.secondary};
          background: transparent;
          color: ${(props) => props.theme.colors.secondaryAlternative || props.theme.colors.secondary};

          &:not([disabled]):hover {
            background-color: ${(props) => (props) =>
              props.theme.colors.secondaryAlternative || props.theme.colors.secondary};
            color: ${(props) => props.theme.colors.body};
          }
        `}
    `}

  ${(props) =>
    props.white &&
    css`
      background: white;
      color: black;

      ${(props) =>
        props.outlined &&
        css`
          border: 2px solid
            ${(props) =>
              readableColor(
                props.theme.colors.secondary,
                props.theme.colors.buttons.darkColor,
                props.theme.colors.buttons.lightColor,
                false
              )};
          background: transparent;
          color: ${(props) =>
            readableColor(
              props.theme.colors.secondary,
              props.theme.colors.buttons.darkColor,
              props.theme.colors.buttons.lightColor,
              false
            )};

          &:not([disabled]):hover {
            background-color: ${(props) =>
              readableColor(
                props.theme.colors.secondary,
                props.theme.colors.buttons.darkColor,
                props.theme.colors.buttons.lightColor,
                false
              )};
            color: ${(props) =>
              readableColor(
                props.theme.colors.secondary,
                props.theme.colors.buttons.lightColor,
                props.theme.colors.buttons.darkColor,
                false
              )};
          }
        `}
    `}

  ${(props) =>
    props.success &&
    css`
      background: ${(props) => props.theme.colors.success};
      color: ${(props) =>
        readableColor(
          props.theme.colors.success,
          props.theme.colors.buttons.darkColor,
          props.theme.colors.buttons.lightColor,
          false
        )};

      &:disabled,
      &[disabled] {
        opacity: 0.5;
      }

      &:not(:disabled),
      &:not([disabled]) {
        &:hover {
          background-color: ${lighten(0.05, props.theme.colors.success)};
        }
      }
    `}

  ${(props) =>
    props.danger &&
    css`
      background: ${(props) => props.theme.colors.danger};
      color: ${(props) =>
        readableColor(
          props.theme.colors.secondary,
          props.theme.colors.buttons.darkColor,
          props.theme.colors.buttons.lightColor,
          false
        )};

      &:disabled,
      &[disabled] {
        opacity: 0.5;
      }

      &:not(:disabled),
      &:not([disabled]) {
        &:hover {
          background-color: ${lighten(0.05, props.theme.colors.danger)};
        }
      }
    `}

    ${(props) =>
    !!props.small &&
    css`
      padding: 0.5rem 1.2rem;
      font-size: 14px;
    `}
`

export const StyledLink = styled(Link)`
  ${styles}
`

export const StyledA = styled.a`
  ${styles}
`

export const StyledButton = styled.button`
  ${styles}
`
