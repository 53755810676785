import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"

export const Button = styled.a`
  color: ${(props) => props.theme.colors.body};
  background-color: transparent;
  border: none;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-align: center;
  font-weight: normal;
  apperance: none;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.small &&
    css`
      width: 2rem;
      height: 2rem;
      font-size: 1rem;
    `}

  ${(props) =>
    props.mobileOnly &&
    breakpoint("desktop")`
    display: none;
  `}
`
