import backgroundImage from "../assets/images/backgrounds/resortleaders.jpg"
import { tint } from "polished"

const resortLeadersGreen = "#004737"
const kokomo = "#F4DF60"
const keyLimePie = "#8DD5AA"
const snowfall = "#E9ECDE"
const surfin = "#0085F0"
const heatwave = "#F96B23"
const lightGrey = "#F8F8F8"
const midLightGrey = "rgb(158, 158, 158)"
const midGrey = "rgb(102, 102, 102)"
const nightsky = "#001E17"
const white = "#FFFFFF"
const alert = "#FF2230"
const inputFieldGrey = "#F6F6F6"
const inputFieldBlack = "#515151"
const inputFieldBorder = "#CCCCCC"
const inputFieldHint = "#999999"

const resortleaders = {
  colors: {
    brand: resortLeadersGreen,
    brand2: snowfall,
    brandShadow: nightsky,
    body: white,
    bodyAlternative: resortLeadersGreen,
    background: white,
    primary: resortLeadersGreen,
    secondary: snowfall,
    secondaryAlternative: nightsky,
    whiteAlternative: nightsky,
    primaryContrasted: tint(0.2, resortLeadersGreen),
    primaryContrastedDarker: tint(0.3, resortLeadersGreen),
    success: keyLimePie,
    warning: kokomo,
    danger: heatwave,
    lightGrey: lightGrey,
    midLightGrey: midLightGrey,
    midGrey: midGrey,
    darkGrey: nightsky,
    linkColor: kokomo,
    rgbaBrandLightened: "rgb(0 71 55 / 10%)",
    notifications: {
      meta: resortLeadersGreen,
      badge: {
        background: alert,
        text: white
      }
    },
    profile: {
      headerBg: resortLeadersGreen,
      avatarBackground: nightsky,
      buttons: {
        primary: {
          background: kokomo,
          text: nightsky
        },
        secondary: {
          background: white,
          text: white
        }
      },
      badge: {
        background: "#f2c400",
        text: "#004252"
      }
    },
    logoutButton: {
      main: kokomo,
      text: nightsky
    },
    statuses: {
      locked: heatwave,
      incomplete: nightsky,
      complete: keyLimePie,
      started: kokomo,
      rejected: heatwave
    },
    home: {
      headings: resortLeadersGreen
    },
    banner: {
      background: "#02ADE51A",
      text: "#004152"
    },
    content: {
      background: lightGrey,
      text: nightsky,
      url: surfin
    },
    contactInfo: {
      background: lightGrey,
      title: resortLeadersGreen,
      text: nightsky
    },
    applicationProgress: {
      value: heatwave,
      bar: "#eeeeee",
      label: "#717171",
      stage: "#19434a"
    },
    tables: {
      headings: resortLeadersGreen
    },
    buttons: {
      color: nightsky,
      lightColor: white,
      darkColor: nightsky,
      special: {
        main: kokomo,
        text: resortLeadersGreen
      }
    },
    incompletion: {
      lightenAmount: 0.3,
      background: heatwave
    },
    fields: {
      text: inputFieldBlack,
      contentText: inputFieldBlack,
      contentHeading: "#666666",
      label: "#333333",
      border: inputFieldBorder,
      hint: inputFieldHint,
      fieldsetText: "#717171",
      backButton: "#CCCCCC",
      backButtonText: "#666666",
      invalid: {
        darkenAmount: 0.125
      },
      backgroundColor: inputFieldGrey,
      spacer: "#F2F2F2"
    },
    alerts: {
      info: "#004152",
      danger: "#FF2230",
      success: "#8FCB24"
    },
    conversations: {
      activeChat: "rgba(8, 41, 61, 0.1)"
    }
  },
  fonts: {
    body: {
      family: "Helvetica Neue",
      weight: "400"
    },
    headings: {
      family: "Helvetica Neue",
      weight: "700"
    }
  },
  images: {
    background: backgroundImage
  }
}

export default resortleaders
