import { useContext } from "react"
import PropTypes from "prop-types"
import UserContext from "@context/UserContext"
import { LiveChatWidget } from "@livechat/widget-react"

const IntegrationLiveChatWidget = (props) => {
  const { menu, setMenu } = useContext(UserContext)

  const handleNewVisibility = ({ visibility }) => {
    setMenu({ ...menu, liveChat: { ...menu.liveChat, show: visibility !== "hidden" } })
  }

  return (
    <LiveChatWidget
      license={props.integration_key}
      visibility={props.show ? "maximized" : "hidden"}
      onVisibilityChanged={handleNewVisibility}
    />
  )
}

IntegrationLiveChatWidget.propTypes = {
  integration_key: PropTypes.string.isRequired
}

export default IntegrationLiveChatWidget
