import React, { useContext } from "react"
import UserContext from "@context/UserContext"
import { Container } from "./styles"

const Tagline = (props) => {
  const user = useContext(UserContext).user
  const name = user.preferred_name && user.preferred_name.length > 0 ? user.preferred_name : user.first_name
  const timeOfDayString = () => {
    const today = new Date()
    const hour = today.getHours()

    switch (true) {
      case hour > 0 && hour < 12:
        return "morning"
      case hour >= 12 && hour < 18:
        return "afternoon"
      case hour >= 18:
        return "evening"
      default:
        return "day"
    }
  }

  const taglineText = `Good ${timeOfDayString()}, ${name}.`
  return <Container>{taglineText}</Container>
}

export default Tagline
