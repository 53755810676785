import backgroundImage from "../assets/images/backgrounds/adventureasia.jpg"
import { lighten, shade } from "polished"

const primary = "#D73D3D"
const secondary = "#221F20"
const yellow = "#E89D3A"
const green = "#285B55"
const primaryLight = lighten(0.1, primary)
const lightGrey = "#F8F8F8"
const midLightGrey = "rgb(158, 158, 158)"
const midGrey = "rgb(102, 102, 102)"
const darkGrey = "#221F20"
const white = "#FFFFFF"
const alert = primary
const inputFieldGrey = "#F6F6F6"
const inputFieldBlack = "#515151"
const inputFieldBorder = "#CCCCCC"
const inputFieldHint = "#999999"

const adventureasia = {
  colors: {
    brand: primary,
    brand2: secondary,
    body: white,
    background: white,
    primary: primary,
    primaryContrasted: shade(0.2, primary),
    primaryContrastedDarker: shade(0.3, primary),
    secondary: secondary,
    success: green,
    warning: yellow,
    danger: primaryLight,
    lightGrey: lightGrey,
    midLightGrey: midLightGrey,
    midGrey: midGrey,
    darkGrey: darkGrey,
    rgbaBrandLightened: "rgb(215 61 61 / 10%)",
    notifications: {
      meta: primary,
      badge: {
        background: alert,
        text: white
      }
    },
    profile: {
      headerBg: primary,
      avatarBackground: secondary,
      buttons: {
        primary: {
          background: green,
          text: white
        },
        secondary: {
          background: white,
          text: white
        }
      },
      badge: {
        background: "#f2c400",
        text: "#004252"
      }
    },
    logoutButton: {
      main: secondary,
      text: white
    },
    statuses: {
      locked: darkGrey,
      incomplete: darkGrey,
      complete: green,
      started: yellow,
      rejected: primaryLight
    },
    home: {
      headings: secondary
    },
    banner: {
      background: "#02ADE51A",
      text: "#004152"
    },
    content: {
      background: lightGrey,
      text: secondary,
      url: primary
    },
    contactInfo: {
      background: lightGrey,
      title: secondary,
      text: primary
    },
    applicationProgress: {
      value: primary,
      bar: "#eeeeee",
      label: "#717171",
      stage: "#19434a"
    },
    tables: {
      headings: primary
    },
    buttons: {
      color: darkGrey,
      lightColor: white,
      darkColor: darkGrey,
      special: {
        main: secondary,
        text: white
      }
    },
    incompletion: {
      lightenAmount: 0.3,
      background: yellow
    },
    fields: {
      text: inputFieldBlack,
      contentText: inputFieldBlack,
      contentHeading: "#666666",
      label: "#333333",
      border: inputFieldBorder,
      hint: inputFieldHint,
      fieldsetText: "#717171",
      backButton: "#CCCCCC",
      backButtonText: "#666666",
      invalid: {
        darkenAmount: 0.125
      },
      backgroundColor: inputFieldGrey,
      spacer: "#F2F2F2"
    },
    alerts: {
      info: "#004152",
      danger: "#FF2230",
      success: "#8FCB24"
    },
    conversations: {
      activeChat: "rgba(2, 173, 229, 0.1)"
    }
  },
  fonts: {
    body: {
      family: "Helvetica Neue",
      weight: "400"
    },
    headings: {
      family: "Helvetica Neue",
      weight: "700"
    }
  },
  images: {
    background: backgroundImage
  }
}

export default adventureasia
