import React, { useMemo, useState, useCallback } from "react"
import { useCookies } from "react-cookie"
import Routes from "../../routes"

import { ThemeProvider } from "styled-components"
import { PostcardThemeProvider, SimpleAlert } from "@smaller-earth/postcard-components"
import { BrowserRouter } from "react-router-dom"
import { BrandProvider } from "@context/BrandContext"
import { UserProvider } from "@context/UserContext"
import { getRequest } from "@helpers/apiHelpers"
import { getBrandFromId } from "@helpers/dataHelpers"
import { getEnvironment, getBrand, getTheme } from "@helpers/siteHelpers"
import TagManager from "react-gtm-module"
import { positions as alertPositions, Provider as AlertProvider } from "react-alert"
import { isEmpty } from "lodash"
import { googleTags, getGoogleTag } from "@helpers/googleTagHelpers"
import { fetchFathomSiteId } from "@helpers/fathomSiteHelpers"
import queryString from "query-string"

import { clarity } from "react-microsoft-clarity"
import * as Fathom from "fathom-client"

const ClarityId = "mttse0nxlc"

const alertOptions = {
  timeout: 5000,
  position: alertPositions.BOTTOM_RIGHT
}

const theme = {
  ...getTheme(),
  breakpoints: {
    mobile: 0,
    tablet: 769,
    desktop: 1025,
    widescreen: 1216,
    fullhd: 1408,
    mahoosive: 1600,
    ludacris: 1800
  }
}

const App = () => {
  const [cookies] = useCookies(["authState"])
  const [isLoaded, setIsLoaded] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState({})
  const [menu, setMenu] = useState({
    checklist: [],
    liveChat: { show: false },
    progressInfo: {},
    userApplications: []
  })

  const getMenuInfo = useCallback(() => {
    const { current_application_container_id, current_user_masquerading, access_token } = user
    getRequest(
      `/participants/application_containers/${current_application_container_id}/menu?&is_user_masquerading=${current_user_masquerading}`,
      access_token
    )
      .then((data) => {
        var checklist = data.checklist_items
        checklist.forEach((item) => {
          if (!!item.locked) item.status = "locked"
          else if (item.percentage === 0) item.status = "not_started"
          else if (item.incompletion_exists) item.status = "incomplete"
          else if (item.percentage === 100) item.status = "complete"
          else item.status = "started"
        })

        const applications = data.application_containers
        applications.forEach((application) => {
          application.brand = getBrandFromId(application.channel_id)
        })
        let currentApplication = applications.find(
          (application) => application.application_container_id === current_application_container_id
        )
        if (currentApplication === undefined) currentApplication = null

        setMenu({
          ...data,
          checklist: checklist,
          currentApplication: currentApplication,
          helpSupportUrl: data.help_support_url,
          communityUrl: data.community_url,
          liveChat: {
            show: false,
            integration_type: data.live_chat_integration_type,
            integration_key: data.live_chat_integration_key,
            integration_user_attributes: data.live_chat_integration_user_attributes
          },
          messageCount: data.message_count,
          messagingEnabled: data.messaging_enabled,
          notificationCount: data.notification_count,
          perksEnabled: data.perks_enabled,
          progressInfo: data.progress_info,
          showEmployers: data.show_employers,
          userApplications: applications
        })
      })
      .catch((error) => console.error(error))
  }, [user])

  useMemo(() => {
    if (isLoaded) return

    const sessionAuthState = sessionStorage["authState"]
    let authState
    let isAuthenticated = false
    let user = {}

    const setTracker = (user = null) => {
      const channel = getBrand().friendlyName
      const environment = getEnvironment()
      if (environment !== "production") return

      // skip tracking if masquerading
      if (user && user.current_user_masquerading) return

      // init Fathom
      const fathomSiteId = fetchFathomSiteId(channel, user)
      if (fathomSiteId) {
        if (window.fathom) {
          Fathom.setSite(fathomSiteId)
        } else {
          Fathom.load(fathomSiteId, { spa: "auto" })
        }
      }

      // init clarity
      clarity.init(ClarityId)
      if (user && user.id && clarity.hasStarted()) {
        clarity.identify(`${user.id}`, {
          email: user.email
        })
      }

      // init global GTM (if we haven't already)
      if (window.dataLayer) return
      TagManager.initialize({ gtmId: googleTags.GLOBAL })

      // init per-locale GTM
      const params = queryString.parse(window.location.href)
      const locale = user?.locale || params.country_code
      const gtmId = getGoogleTag(channel, locale)
      if (!!gtmId) TagManager.initialize({ gtmId: gtmId })
    }

    if (cookies.authState !== undefined) {
      // We are logged in
      authState = cookies.authState
      isAuthenticated = authState.isAuthenticated
      user = authState.user
    }

    if (!isAuthenticated && sessionAuthState) {
      // We are masquerading
      authState = JSON.parse(sessionAuthState)
      isAuthenticated = authState.isAuthenticated
      user = authState.user
    }

    if (authState) {
      setIsAuthenticated(isAuthenticated)
      setUser(user)
      setTracker(user)
    } else {
      setTracker()
    }

    setIsLoaded(true)
  }, [isLoaded, setIsLoaded, cookies])

  useMemo(() => {
    if (!user || isEmpty(user) || !isAuthenticated) return

    getMenuInfo()
  }, [user, getMenuInfo, isAuthenticated])

  return (
    <>
      {!isLoaded && <></>}

      {isLoaded && (
        <BrandProvider value={getBrand()}>
          <ThemeProvider theme={theme}>
            <PostcardThemeProvider theme={theme}>
              <AlertProvider template={SimpleAlert} {...alertOptions}>
                <UserProvider value={{ user, menu, setUser, setMenu }}>
                  <BrowserRouter>
                    <Routes isAuthenticated={isAuthenticated} />
                  </BrowserRouter>
                </UserProvider>
              </AlertProvider>
            </PostcardThemeProvider>
          </ThemeProvider>
        </BrandProvider>
      )}
    </>
  )
}
export default App
