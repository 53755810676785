import backgroundImage from "../assets/images/backgrounds/campcanada.jpg"
import { shade } from "polished"

const campCanadaOrange = "#FF3D17"
const navy = "#0B3854"
const blue = "#1D95E0"
const red = "#BC1B13"
const yellow = "#FAB700"
const lightGrey = "#F8F8F8"
const midLightGrey = "rgb(158, 158, 158)"
const midGrey = "rgb(102, 102, 102)"
const darkGrey = "#272E32"
const white = "#FFFFFF"
const green = "#99CC00"
const inputFieldGrey = "#F6F6F6"
const inputFieldBlack = "#515151"
const inputFieldBorder = "#CCCCCC"
const inputFieldHint = "#999999"

const campcanada = {
  colors: {
    brand: campCanadaOrange,
    brand2: navy,
    body: white,
    background: white,
    primary: campCanadaOrange,
    secondary: navy,
    primaryContrasted: shade(0.2, campCanadaOrange),
    primaryContrastedDarker: shade(0.3, campCanadaOrange),
    success: blue,
    warning: yellow,
    danger: red,
    lightGrey: lightGrey,
    midLightGrey: midLightGrey,
    midGrey: midGrey,
    darkGrey: darkGrey,
    rgbaBrandLightened: "rgb(255 61 23 / 10%)",
    notifications: {
      meta: blue,
      badge: {
        background: red,
        text: white
      }
    },
    profile: {
      headerBg: campCanadaOrange,
      avatarBackground: navy,
      buttons: {
        primary: {
          background: blue,
          text: white
        },
        secondary: {
          background: white,
          text: white
        }
      },
      badge: {
        background: "#f2c400",
        text: "#004252"
      }
    },
    logoutButton: {
      main: white,
      text: darkGrey
    },
    statuses: {
      locked: red,
      incomplete: darkGrey,
      complete: green,
      started: yellow,
      rejected: red
    },
    home: {
      headings: navy
    },
    banner: {
      background: "#02ADE51A",
      text: "#004152"
    },
    content: {
      background: lightGrey,
      text: darkGrey,
      url: campCanadaOrange
    },
    contactInfo: {
      background: lightGrey,
      title: navy,
      text: campCanadaOrange
    },
    applicationProgress: {
      value: campCanadaOrange,
      bar: "#eeeeee",
      label: "#717171",
      stage: "#19434a"
    },
    tables: {
      headings: campCanadaOrange
    },
    buttons: {
      color: white,
      lightColor: white,
      darkColor: darkGrey,
      special: {
        main: white,
        text: darkGrey
      }
    },
    incompletion: {
      lightenAmount: 0.3,
      background: yellow
    },
    fields: {
      text: inputFieldBlack,
      contentText: inputFieldBlack,
      contentHeading: "#666666",
      label: "#333333",
      border: inputFieldBorder,
      hint: inputFieldHint,
      fieldsetText: "#717171",
      backButton: "#CCCCCC",
      backButtonText: "#666666",
      invalid: {
        darkenAmount: 0.2
      },
      backgroundColor: inputFieldGrey,
      spacer: "#F2F2F2"
    },
    alerts: {
      info: "#004152",
      danger: "#FF2230",
      success: "#8FCB24"
    },
    conversations: {
      activeChat: "rgba(255, 60, 23, 0.1)"
    }
  },
  fonts: {
    body: {
      family: "Helvetica Neue",
      weight: "400"
    },
    headings: {
      family: "Helvetica Neue",
      weight: "700"
    }
  },
  images: {
    background: backgroundImage
  }
}

export default campcanada
