import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

export const Button = styled.button`
  color: ${(props) => props.theme.colors.body};
  background-color: transparent;
  border: none;
  width: 2rem;
  height: 2rem;
  font-size: 22px;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  apperance: none;
  box-sizing: border-box;
  cursor: pointer;

  ${breakpoint("desktop")`
    font-size: 35px;
    width: 2.5rem;
    height: 2.5rem;
  `}
`
