const campleaders = {
  id: 3,
  name: "campleaders",
  friendlyName: "Camp Leaders",
  tagline: "Your summer of a lifetime.",
  apply: "Apply now for summer 2024",
  links: {
    appBase: {
      development: "http://campleaders.test:3000",
      staging: "https://mf-staging.campleaders.com",
      production: "https://login.campleaders.com"
    },
    baseWithoutProtocol: "www.campleaders.com"
  },
  employerType: "Camp"
}

export default campleaders
