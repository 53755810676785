import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

export const Container = styled.span`
  z-index: 10;
  color: ${(props) => props.theme.colors.body};
  font-family: ${(props) => props.theme.fonts.headings.family}, sans-serif;
  font-weight: ${(props) => props.theme.fonts.headings.weight};
  font-size: 32px;
  letter-spacing: -1px;
  padding: 0.4em;
  margin-top: 4rem;
  pointer-events: none;
  user-select: none;

  ${breakpoint("tablet")`
    font-size: 56px;
    letter-spacing: -2px;
    padding: 22px;
    margin-top: 0;
    `}

  ${breakpoint("desktop")`
    padding: 30px;
  `}
`
