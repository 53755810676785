import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { setLightness } from "polished"

export const StyledHr = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  margin: 1rem 0;
  padding: 0;
  border-top: 1px solid;
  border-color: ${(props) => props.theme.colors.secondary};

  ${(props) =>
    props.mobileOnly &&
    breakpoint("desktop")`
    display: none;
  `}

  ${(props) =>
    props.light &&
    css`
      border-color: ${props.theme.colors.primary};
    `}

  ${(props) =>
    props.shadow &&
    css`
      border-color: ${setLightness(0.4, props.theme.colors.primaryContrastedDarker)};
    `}

  ${(props) =>
    props.body &&
    css`
      border-color: ${props.theme.colors.body};
    `}

  ${(props) =>
    props.halfMargin &&
    css`
      margin: 1.5rem 2rem;
    `}

  ${(props) =>
    props.halfMargin &&
    breakpoint("desktop")`
      margin: 0.5rem 1rem;
  `}
`
