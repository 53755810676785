import backgroundImage from "../assets/images/backgrounds/adventurechina.jpg"
import { shade } from "polished"

const adventureChinaRed = "#BD2126"
const deepRed = "#7D161A"
const brightGold = "#FBD191"
const gold = "#F7DA94"
const lightGrey = "#F8F8F8"
const midLightGrey = "rgb(158, 158, 158)"
const midGrey = "rgb(102, 102, 102)"
const darkGrey = "#1F2B30"
const white = "#FFFFFF"
const green = "#99CC00"
const inputFieldGrey = "#F6F6F6"
const inputFieldBlack = "#515151"
const inputFieldBorder = "#CCCCCC"
const inputFieldHint = "#999999"

const adventurechina = {
  colors: {
    brand: adventureChinaRed,
    brand2: deepRed,
    brandShadow: deepRed,
    body: white,
    background: white,
    primary: adventureChinaRed,
    secondary: deepRed,
    primaryContrasted: shade(0.2, adventureChinaRed),
    primaryContrastedDarker: shade(0.3, adventureChinaRed),
    success: gold,
    warning: gold,
    danger: adventureChinaRed,
    lightGrey: lightGrey,
    midLightGrey: midLightGrey,
    midGrey: midGrey,
    darkGrey: darkGrey,
    rgbaBrandLightened: "rgb(236 30 39 / 10%)",
    notifications: {
      meta: gold,
      badge: {
        background: deepRed,
        text: white
      }
    },
    profile: {
      headerBg: adventureChinaRed,
      avatarBackground: deepRed,
      buttons: {
        primary: {
          background: gold,
          text: darkGrey
        },
        secondary: {
          background: white,
          text: white
        }
      },
      badge: {
        background: "#f2c400",
        text: "#004252"
      }
    },
    logoutButton: {
      main: brightGold,
      text: darkGrey
    },
    statuses: {
      locked: deepRed,
      incomplete: darkGrey,
      complete: green,
      started: brightGold,
      rejected: adventureChinaRed
    },
    home: {
      headings: deepRed
    },
    banner: {
      background: "#02ADE51A",
      text: "#004152"
    },
    content: {
      background: lightGrey,
      text: darkGrey
    },
    contactInfo: {
      background: lightGrey,
      title: darkGrey,
      text: adventureChinaRed
    },
    applicationProgress: {
      value: adventureChinaRed,
      bar: "#eeeeee",
      label: "#717171",
      stage: "#19434a"
    },
    tables: {
      headings: gold
    },
    buttons: {
      color: white,
      lightColor: white,
      darkColor: darkGrey,
      special: {
        main: brightGold,
        text: darkGrey
      }
    },
    incompletion: {
      lightenAmount: 0.2,
      background: gold
    },
    fields: {
      text: inputFieldBlack,
      contentText: inputFieldBlack,
      contentHeading: "#666666",
      label: "#333333",
      border: inputFieldBorder,
      hint: inputFieldHint,
      fieldsetText: "#717171",
      backButton: "#CCCCCC",
      backButtonText: "#666666",
      invalid: {
        darkenAmount: 0.3
      },
      backgroundColor: inputFieldGrey,
      spacer: "#F2F2F2"
    },
    alerts: {
      info: "#004152",
      danger: "#FF2230",
      success: "#8FCB24"
    },
    conversations: {
      activeChat: "rgba(236, 31, 40, 0.1)"
    }
  },
  fonts: {
    body: {
      family: "Helvetica Neue",
      weight: "400"
    },
    headings: {
      family: "Helvetica Neue",
      weight: "700"
    }
  },
  images: {
    background: backgroundImage
  }
}

export default adventurechina
