import { touchUserContextData } from "@helpers/userHelper"

export function getRequest(path, token = "", userContext = null, signal = null, version = "v1") {
  const base = version === "v1" ? process.env.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_V2_BASE_URL
  const payload = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": process.env.REACT_APP_API_KEY,
      "X-AUTH-TOKEN": token
    }
  }
  if (signal !== null) payload["signal"] = signal
  return fetch(`${base}${path}`, payload).then((response) => {
    if (signal !== null && signal.aborted) {
      return false
    } else {
      if (userContext !== null) touchUserContextData(userContext)
      return handleResponse(response)
    }
  })
}

export function getFileRequest(url, token = "") {
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": process.env.REACT_APP_API_KEY,
      "X-AUTH-TOKEN": token
    }
  }).then((response) => handleFileErrors(response))
}

export function postRequest(path, token = "", body = "", userContext = null, version = "v1") {
  const base = version === "v1" ? process.env.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_V2_BASE_URL
  return fetch(`${base}${path}`, {
    method: "POST",
    body: body,
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": process.env.REACT_APP_API_KEY,
      "X-AUTH-TOKEN": token
    }
  }).then((response) => {
    if (userContext !== null) touchUserContextData(userContext)
    return handleResponse(response)
  })
}

export function patchRequest(path, token = "", body = "", userContext = null) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}${path}`, {
    method: "PATCH",
    body: body,
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": process.env.REACT_APP_API_KEY,
      "X-AUTH-TOKEN": token
    }
  }).then((response) => {
    if (userContext !== null) touchUserContextData(userContext)
    return handleResponse(response)
  })
}

export function patchRequestNoContentType(path, token = "", body = "", userContext = null) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}${path}`, {
    method: "PATCH",
    body: body,
    headers: {
      "X-API-KEY": process.env.REACT_APP_API_KEY,
      "X-AUTH-TOKEN": token
    }
  }).then((response) => {
    if (userContext !== null) touchUserContextData(userContext)
    return handleResponse(response)
  })
}

export function deleteRequest(path, token = "", userContext = null) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}${path}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": process.env.REACT_APP_API_KEY,
      "X-AUTH-TOKEN": token
    }
  }).then((response) => {
    if (userContext !== null) touchUserContextData(userContext)
    return handleResponse(response)
  })
}

function handleResponse(response) {
  try {
    return response.json().then((result) => {
      // if (process.env.REACT_APP_ENV === 'development') console.log(result)
      console.log(result)

      if (shouldInterceptResponse(response)) {
        interceptResponse(response)
      }

      if (response.ok) {
        return result
      } else {
        return handleErrors(result)
      }
    })
  } catch (error) {
    console.error(error)
    throw new Error(response.statusText)
  }
}

function shouldInterceptResponse(response) {
  // process unless we are hitting sign_in
  const url = response?.url
  if (!url) return true
  if (url.includes("/sessions/sign_in")) return false
  return response.status === 401
}

function interceptResponse(response) {
  switch (response.status) {
    case 401:
      intercept401Response(response)
      break
    default:
      break
  }
}

function intercept401Response(response) {
  let authState = {
    isAuthenticated: false,
    user: {}
  }

  document.cookie = `authState=; path=/; max-age=0;`
  sessionStorage["authState"] = JSON.stringify(authState)

  window.location.href = `/login?code=${response.status}&redirect_to=${window.location.pathname}`
}

function handleErrors(result) {
  console.log("result", result)
  throw new Error(extractErrorFromResult(result))
}

function extractErrorFromResult(result) {
  try {
    if ("error" in result) {
      if (typeof result["error"] === "object" && result["error"] !== null) return result["error"]["message"]
      else return result["error"]
    } else if ("errors" in result && Array.isArray(result["errors"])) {
      return result["errors"].join(", ").replace('["', "").replace('"]', "")
    } else if ("message" in result) {
      return result["message"]
    } else {
      return "Something went wrong"
    }
  } catch (error) {
    console.error(error)
    return "Something went wrong"
  }
}

function handleFileErrors(response) {
  console.log(response)
  if (response.ok) return response.blob()
  else throw new Error(response.statusText)
}

export function prepareParams(object = {}, whitelist = []) {
  return Object.keys(object)
    .filter((key) => whitelist.includes(key))
    .reduce((obj, key) => {
      obj[key] = object[key]
      return obj
    }, {})
}
