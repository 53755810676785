import React from "react"
import { GlobalStyles, Container } from "./styles"

const LoadingLayout = ({ children }) => (
  <Container>
    <GlobalStyles />
    {children}
  </Container>
)

export default LoadingLayout
