import React, { useContext } from "react"
import UserContext from "@context/UserContext"
import LoadingLayout from "@layouts/loading-layout"
import PrivateLayout from "@layouts/private-layout"
import LoadingBox from "@localComponents/loading-box"
import LoadingSpinner from "../loading-spinner"
import Message from "./message"

const Loading = (props) => {
  const { pastDelay, error, timedOut, retry, inProfileChecklist } = props
  const { user } = useContext(UserContext)

  if (user && user.id) {
    return <PrivateLayout inProfileChecklist={inProfileChecklist}>{pastDelay && <LoadingBox />}</PrivateLayout>
  } else {
    return (
      <LoadingLayout>
        {pastDelay && !error && !timedOut && <LoadingSpinner />}
        {error && <Message text="An error occurred" retry={retry} />}
        {timedOut && <Message text="Request timed out" retry={retry} />}
      </LoadingLayout>
    )
  }
}

export const LoadingInProfileChecklist = (props) => {
  return <Loading {...props} inProfileChecklist />
}

export default Loading
