import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { StyledHeading } from "./styles"
import Separator from "../separator"

const Heading = ({ title, simple, showSeparator }) => (
  <Fragment>
    <StyledHeading simple={simple}>{title}</StyledHeading>
    {!simple && showSeparator && <Separator secondary />}
  </Fragment>
)

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  showSeparator: PropTypes.bool.isRequired,
  simple: PropTypes.bool
}

Heading.defaultProps = {
  showSeparator: true
}

export default Heading
