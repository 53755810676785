import React from "react"
import { Container, Circle } from "./styles"

const LoadingSpinner = ({ primary }) => (
  <Container primary={primary}>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
    <Circle></Circle>
  </Container>
)

export default LoadingSpinner
