import React from "react"
import PropTypes from "prop-types"
import IntegrationLiveChatWidget from "@integrations/IntegrationLiveChatWidget"

const integrationComponentMap = {
  livechat: IntegrationLiveChatWidget
}

const LiveChat = (props) => {
  const Integration = integrationComponentMap[props.integration_type]
  return Integration ? <Integration {...props} /> : null
}

LiveChat.propTypes = {
  integration_type: PropTypes.string,
  integration_key: PropTypes.string
}

export default LiveChat
