import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { circleStyles } from "../loading-spinner/styles"

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`

export const Spinner = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100px;
  height: 100px;
`

export const Circle = styled.span`
  background-color: white;

  ${breakpoint("desktop")`
    background-color: ${(props) => props.theme.colors.primary};
  `}

  ${circleStyles}
`
