import React, { useContext } from "react"
import MenuContext from "@context/MenuContext"
import UserContext from "@context/UserContext"
import { Container, Header, CancelLink, ApplicationLink } from "./styles"
import CloseButton from "@localComponents/close-button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faCheck } from "@fortawesome/free-solid-svg-icons"

const MobileApplicationList = (props) => {
  const {
    showMobileApplicationList,
    toggleMobileApplicationList,
    toggleMenuAndMobileApplicationList,
    changeApplication
  } = useContext(MenuContext)
  const { currentApplication, userApplications } = useContext(UserContext).menu

  const listStyle = {
    margin: "0",
    padding: "1.2em",
    listStyle: "none"
  }

  const listItemStyle = {
    marginBottom: "1.5rem"
  }

  return (
    <Container show={showMobileApplicationList}>
      <Header>
        <CancelLink to="#" onClick={toggleMobileApplicationList}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>Cancel</span>
        </CancelLink>
        <CloseButton id="mobileCancelApplicationListButton" onClick={toggleMenuAndMobileApplicationList} mobileOnly />
      </Header>

      <ul style={listStyle}>
        {userApplications.map((application) => {
          const isCurrent = application.application_container_id === currentApplication.application_container_id
          return (
            <li style={listItemStyle} key={application.application_container_id}>
              <ApplicationLink
                active={isCurrent}
                onClick={changeApplication}
                data-id={application.application_container_id}
                data-brand={application.brand}
              >
                <span>{application.programme_name}</span>
                {isCurrent && <FontAwesomeIcon icon={faCheck} />}
              </ApplicationLink>
            </li>
          )
        })}
      </ul>
    </Container>
  )
}

export default MobileApplicationList
