import React from "react"
import PropTypes from "prop-types"
import { Button } from "./styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/pro-regular-svg-icons"

const OpenMenuButton = ({ toggleMenu }) => (
  <Button onClick={toggleMenu}>
    <FontAwesomeIcon icon={faBars} size="1x" />
  </Button>
)

OpenMenuButton.propTypes = {
  toggleMenu: PropTypes.func.isRequired
}

export default OpenMenuButton
