import React from "react"
import PropTypes from "prop-types"
import { Container, Text, Progress, Blocks } from "./styles"

const ApplicationProgress = (props) => {
  const { currentStageRank, maxStageRank, stage, progressPercentage, body } = props
  const stages = [...Array(maxStageRank).keys()]

  return (
    <Container {...props}>
      {stage.length > 0 && (
        <div>
          <Text body={body}>
            Application Stage:&nbsp;<span>{stage}</span>
          </Text>

          <Blocks>
            {stages.map((i) => {
              const stagePlace = i + 1
              const isCompleted = stagePlace < currentStageRank

              return currentStageRank === stagePlace ? (
                <Progress key={i} value={progressPercentage} stage={stagePlace} lastStage={stages.length} max="100" />
              ) : (
                <Progress
                  key={i}
                  value={isCompleted ? "100" : "0"}
                  stage={stagePlace}
                  lastStage={stages.length}
                  max="100"
                />
              )
            })}
          </Blocks>
        </div>
      )}
    </Container>
  )
}

ApplicationProgress.propTypes = {
  currentStageRank: PropTypes.number.isRequired,
  maxStageRank: PropTypes.number.isRequired,
  stage: PropTypes.string.isRequired,
  progressPercentage: PropTypes.number.isRequired,
  inEndStage: PropTypes.bool
}

ApplicationProgress.defaultProps = {
  currentStageRank: 0,
  maxStageRank: 0,
  stage: "",
  progressPercentage: 0
}

export default ApplicationProgress
