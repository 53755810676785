import styled, { createGlobalStyle, css } from "styled-components"
import breakpoint from "styled-components-breakpoint"

export const GlobalStyles = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    height: 100vh;
    width: 100%;
    font-size: 16px;
    font-family: ${(props) => props.theme.fonts.body.family}, sans-serif;
    font-weight: ${(props) => props.theme.fonts.body.weight};
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
  }

  @supports (-webkit-touch-callout: none) {
    html {
      height: -webkit-fill-available !important;
    }
  }

  body {
    color: ${(props) => props.theme.colors.body};
    margin: 0;
    padding: 0;

    ${({ simpleForm }) =>
      !simpleForm &&
      css`
        background-color: ${(props) => props.theme.colors.primary};
      `}


    ${breakpoint("desktop")`
      background-color: ${(props) => props.theme.colors.background};
      overflow-x: hidden;
    `}
  }
`

export const Header = styled.div`
  text-align: right;
  padding: 1rem 1rem 0 1rem;

  ${breakpoint("desktop")`
    display: none;
  `}
`

export const Main = styled.main`
  ${({ simpleForm }) =>
    !simpleForm &&
    css`
      padding: 2rem 1rem 12rem 1rem;

      ${breakpoint("tablet")`
        padding: 3rem 1rem 6rem 1rem;
      `}
    `}

  ${({ simpleForm }) =>
    !!simpleForm &&
    css`
      padding: 2rem 24px 12rem 24px;
      max-width: 89%;
      max-width: 89vw;
    `}



  ${breakpoint("desktop")`
    padding: 0 0 3rem 0;

    ${({ simpleForm }) =>
      !!simpleForm &&
      css`
        max-width: 672px;
        margin: 2rem auto;
      `}
  `}
`

export const Content = styled.div`
  ${breakpoint("desktop")`
    width: 65%;
    width: 65vw;
    width: 65dvw;
    padding: 2rem;
    flex-grow: 1;
  `}
`
export const Body = styled.div`
  ${breakpoint("desktop")`
    display: flex;
    justify-content: start;
    align-items: start;
  `}
`
