const campcanada = {
  id: 12,
  name: "campcanada",
  friendlyName: "Camp Canada",
  tagline: "Your summer, elevated.",
  apply: "Apply now for summer 2024",
  links: {
    appBase: {
      development: "http://campcanada.test:3000",
      staging: "https://mf-staging.campcanada.ca",
      production: "https://login.campcanada.ca"
    },
    baseWithoutProtocol: "www.campcanada.ca"
  },
  employerType: "Camp"
}

export default campcanada
