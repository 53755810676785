import React from "react"
import PropTypes from "prop-types"
import { StyledLink, StyledA, StyledButton } from "./styles"

const Button = (props) => {
  if (props.to) return <StyledLink {...props}>{props.text}</StyledLink>
  else if (props.href)
    return (
      <StyledA {...props} rel="noopender noreferrer">
        {props.text}
      </StyledA>
    )
  else return <StyledButton {...props}>{props.text}</StyledButton>
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  collapsed: PropTypes.bool,
  alwaysCollapsed: PropTypes.bool,
  link: PropTypes.bool,
  inline: PropTypes.bool,
  outlined: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  white: PropTypes.bool,
  success: PropTypes.bool,
  danger: PropTypes.bool,
  onClick: PropTypes.func,
  small: PropTypes.bool
}

export default Button
